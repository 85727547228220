module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Parasky Flute Studios","short_name":"Parasky Studios","start_url":"/","background_color":"#485fc7","theme_color":"#485fc7","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9aba405a6df0e53a73a049b6efc7e282"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"whitelabel":false,"lang":"en","siteId":2223791,"cookiePolicyId":27875348,"countryDetection":true,"footer":{},"banner":{"acceptButtonDisplay":true,"customizeButtonDisplay":true,"position":"float-bottom-right","acceptButtonColor":"#1255cc","acceptButtonCaptionColor":"white","customizeButtonColor":"#242424","customizeButtonCaptionColor":"white","rejectButtonColor":"#1255cc","rejectButtonCaptionColor":"white","textColor":"white","backgroundColor":"#121212"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-193876640-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
